<template>
    <div>
        <AdminTop
            heading="Klasser"
            add-new-url="/admin/classes/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Namn
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(schoolClass, index) in schoolClasses"
                    :key="schoolClass.id"
                    :to="`/admin/classes/${schoolClass.id}`"
                    @delete="removeClassroom(schoolClass.id, index)"
                >
                    <TableCell>
                        <div class="flex items-center">
                            <p class="text-gray-900 whitespace-no-wrap">
                                {{ schoolClass.name }}
                            </p>
                        </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import SchoolClassService from '@/services/admin/SchoolClassService';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell
    },

    data: () => ({
        schoolClasses: []
    }),

    created () {
        this.getClassrooms();
    },

    methods: {
        async getClassrooms () {
            const res = await SchoolClassService.all();

            this.schoolClasses = res.data;
        },

        async removeClassroom (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await SchoolClassService.remove(id);

            if (res && res.status === 200) {
                const schoolClasses = [...this.schoolClasses];

                schoolClasses.splice(index, 1);

                this.schoolClasses = schoolClasses;
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
</style>
