import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/school-classes');
    },

    get (schoolClassId) {
        return API.apiClient.get(`/admin/school-classes/${schoolClassId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/school-classes', payload);
    },

    update (schoolClassId, payload) {
        return API.apiClient.put(`/admin/school-classes/${schoolClassId}`, payload);
    },

    remove (schoolClassId) {
        return API.apiClient.delete(`/admin/school-classes/${schoolClassId}`);
    }
};
